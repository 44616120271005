import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import "../style/Footer.css";
import { Instagram, Facebook, Linkedin } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <>
      <Container className="sitefooter ">
        <div>
          <img
            src="/images/col.png"
            alt="..."
            style={{ height: "500px", width: "100%" }}
          />
        </div>
        <div className="sitefooter-body">
          <Row>
            <h1 className="heading">Contact Us</h1>
            <Col md={2} className="col-2">
              <div className="social-media-icons">
                <a
                  href="https://www.facebook.com/springeveningpl"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginBottom: "5px" }}
                >
                  <Facebook className="iconfacebook" color="blue" size="40px" />
                </a>
                <br></br>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram
                    className="iconinstagram"
                    color="#CC3366"
                    size="40px"
                  />
                </a>
                <br></br>
                <a
                  href="https://www.linkedin.com/company/springevening-private-limited/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin
                    className="iconlinkedin"
                    color="#00008B"
                    size="40px"
                  />
                </a>
              </div>
            </Col>
            <Col md={10} className="d-flex flex-column  para col-10">
              <p>Springevening Private Limited</p>
              <p>Shri Vijaya Concrete Works, Badami Road, Bagalkot -587101</p>
              <p>Phone number: 7019018677</p>
              <p>
                Email: talktous@happynenapu.com or excel@springeveningpl.com
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="text-center">
        © 2024 - 2025 - All Rights Reserved |
        <span>
          {" Site developed by "}
          <a
            href="https://springeveningpl.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Springevening Private Limited
          </a>
        </span>
      </div>
    </>
  );
};
export default Footer;
