import React, { useEffect, useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "../style/Navigation.css";

const Navigation = () => {
  const navbarRef = useRef(null);

  // Effect to handle click outside of navbar to close the navbar in mobile view
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler && !navbarToggler.contains(event.target)) {
          const navbarCollapse = document.querySelector(".navbar-collapse");
          if (navbarCollapse) {
            navbarCollapse.classList.remove("show");
          }
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
        className="bg-secondary"
        ref={navbarRef}
      >
        <Container fluid>
          <div className="mobile-view-logo">
            <img src="/images/logo.png" alt="Logo" width="45px" height="45px" />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="nav-items">
              <Nav.Link href="#home" style={{ fontSize: "20px" }}>
                Home
              </Nav.Link>
              <Nav.Link href="#features" style={{ fontSize: "20px" }}>
                Features
              </Nav.Link>
              <div className="desktop-view-logo">
                <img
                  src="/images/logo.png"
                  alt="Logo"
                  width="45px"
                  height="45px"
                />
              </div>
              <Nav.Link href="#contact" style={{ fontSize: "20px" }}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
