import React from "react";
import "../style/Heromodule.css";

const Heromodule = () => {
  return (
    <div className="container-fluid" id="home">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 d-flex align-items-center">
          <div className="center text-center text-md-left">
            <h1>
              Welcome to <span className="highlight">Happy Nenapu !!!</span>
            </h1>
            <h3>Capturing Joy, One Flip at a Time</h3>
            <p>
              Transform your static content into dynamic experiences with our
              cutting-edge digital flipbook technology.
            </p>
            <p>
              Engage your audience like never before with interactive features,
              seamless navigation, and stunning visuals.
            </p>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-12">
          <div className="hero text-center">
            <a
              href="http://happynenapu.com/assets/innerwheeldistrict317"
              target="_blank"
            >
              <video
                src="/images/heromodule.mp4"
                alt="Happy Nenapu Video"
                className="img-fluid"
                autoPlay
                loop
                muted
                style={{ maxWidth: "100%" }}
              ></video>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heromodule;
