import "../style/Contact.css";
const Contact = () => {
  return (
    <div className="contact-bg container-fluid " id="contact">
      <p className="contact-form">
        <form
          className="row "
          action="https://formbold.com/s/objw8"
          method="post"
        >
          {/* <h2 className="text-secondary mx-auto fs-1"> */}
          <div className="col-12 pt-2  mb-md-5">
            <h2
              className="text-secondary text-center"
              style={{
                fontWeight: "bold",
                letterSpacing: "0.15rem",
              }}
            >
              Fill in the blanks and lets do{" "}
              <span className="highlight"> great things </span> together!
            </h2>
          </div>

          <div className="comment-box col-12 text-wrap text-center mx-auto lh-base ">
            <span className="contact-span">Hi, My name is</span>
            <input
              type="text"
              name="name"
              placeholder="your full name"
              className="contact-input fs-2 mb-0 mb-md-4"
            />
            <span className="contact-span"> please contact me at </span>
            <input
              type="email"
              name="email"
              placeholder="email id"
              className="contact-input fs-2 my-1"
            />
            <span className="contact-span"> or </span>
            <input
              type="text"
              name="phno"
              placeholder="phone no."
              className="contact-input fs-2 my-1"
            />
            <span className="contact-span">. I am seeking assistance to </span>
            <input
              type="text"
              name="comment"
              placeholder="upscale my branding through your flipbook"
              className="contact-input fs-2 px-2 w-50 mb-0 mb-md-4 mt-0 mt-md-4"
            />{" "}
            <span className="contact-span">
              service to grow my business, kindly share your proposal.
            </span>
          </div>
          <div className="text-center my-md-4">
            <button className="rounded p-1 p-md-2">Connect me now!</button>
          </div>
        </form>
      </p>
    </div>
  );
};

export default Contact;
