import ReactGA from "react-ga4";

// Multiple products (previously known as trackers)
ReactGA.initialize([
  {
    trackingId: "G-B9Z8MLDQKB",
    gaOptions: {}, // optional
    gtagOptions: {}, // optional
  },
  {
    trackingId: "G-B9Z8MLDQKB",
  },
]);

// Send pageview with a custom path
// ReactGA.send({ hitType: "pageview", page: "/", title: "InnerWheelTest" });

// Send a custom event
//   ReactGA.event({
//     category: "your category",
//     action: "your action",
//     label: "your label", // optional
//     value: 99, // optional, must be a number
//     nonInteraction: true, // optional, true/false
//     transport: "xhr", // optional, beacon/xhr/image
//   });
