import ColoumnWebsite from "../modules/ColumnWebsite";
import Contact from "../modules/Contact";
import Footer from "../modules/Footer";
import Heromodule from "../modules/Heromodule";
import Navigation from "../modules/Navigation";
import "../modules/Analytics";
import GoogleAds from "../modules/GoogleAds";

const Home = () => {
  return (
    <>
      <Navigation></Navigation>
      <Heromodule></Heromodule>
      <ColoumnWebsite></ColoumnWebsite>
      <Contact></Contact>
      <Footer></Footer>
      <GoogleAds />
    </>
  );
};
export default Home;
