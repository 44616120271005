import React from "react";
import "../style/ColumnWebsite.css";
const ColoumnWebsite = () => {
  return (
    <div id="features">
      <div class="container">
        <div class="row">
          <div className="col-md-6 pt-3 ">
            <img
              src="/images/twocolumnimg.png"
              className="seplimg img-fluid d-flex justify-content-center"
            ></img>
          </div>
          <div class="col-md-6 pt-3 ">
            <h1>
              {" "}
              Why use <span className="highlight"> Happy Nenapu</span> Digital
              Flipbook?
            </h1>
            <ul className="gap-y-2">
              {" "}
              <li className="pb-4 pt-2">
                <span className="fw-bold">Enhanced Engagement:</span> Say
                goodbye to boring static content. Digital flipbooks offer
                interactive elements such as multimedia integration, animations,
                and clickable links, keeping your audience engaged and immersed
                in your content
              </li>
              <li className="pb-4">
                <span className="fw-bold">Cross-Platform Compatibility:</span>
                Reach your audience anytime, anywhere, on any device.
              </li>
              <li className="pb-4">
                <span className="fw-bold"> Cost-Effective Publishing:</span>
                Save on printing and distribution costs by going digital.
              </li>
              <li className="pb-4">
                <span className="fw-bold">Analytics and Insights:</span> Gain
                valuable insights into reader behavior with advanced analytics
                tools. Track engagement metrics, understand reader preferences,
                and optimize your content strategy for maximum impact
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColoumnWebsite;
