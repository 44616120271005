import React, { useEffect, useState } from "react";
import "../style/GoogleAds.css";

const GoogleAds = () => {
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    // fetch("/assets/config.json")
    //   .then((response) => response.json())
    //   .then((config) => {
    //     setShowAds(config.showAds);
    //   })
    //   .catch((error) => {
    //     console.error("Failed to load the configuration:", error);
    //   });
    setShowAds(true);
  }, []);

  useEffect(() => {
    if (!showAds) return;

    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1980288183789631";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
    script.onload = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("AdSense script error:", e);
      }
    };

    script.onerror = () => {
      console.error("Failed to load the AdSense script.");
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [showAds]);

  if (!showAds) return null;

  return (
    <div className="ads-container">
      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1980288183789631"
        data-ad-slot="6934312420"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "728px", height: "90px" }}
        data-ad-client="ca-pub-1980288183789631"
        data-ad-slot="6934312420"
      ></ins>
    </div>
  );
};

export default GoogleAds;
