import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/views/Home";

// Mock GoogleAds component for demonstration
const GoogleAds = () => <div>Google Ads</div>;

function App() {
  const objSeo = {
    title: "HappyNenapu - Capturing Joy, One flip at a time",
    keywords:
      "digital flipbook, happy nenapu, digital diary, memories,flipbook, Bagalkot, Springevening Private Limited",
    pathSlug: "/",
    description:
      "Transform your static content into dynamic experiences with our cutting-edge digital flipbook technology.",
  };

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
